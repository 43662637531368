a {
    &:hover {
        color: $vooozer-secondary;
    }
}

.account-card {
    background-color: $default-ynvox;
}

.color {
    &-white {
        color: $white;
    }

    &-gray {
        color: $gray-500;
    }

    &-red {
        color: $red-alt;
    }

    &-green {
        color: $green-alt;
    }

}

.bg-primary-vooozer {
    background: $vooozer-primary !important;
}

.bg-secondary-vooozer {
    background: $vooozer-secondary !important;
}

.vooozer-link {
    color: $vooozer-secondary;

    &:hover {
        color: lighten($vooozer-secondary, 15%);
        cursor: pointer;
    }
}

.hover-overlay {
    color: #939494 !important;

    &:hover {
        opacity: 0.7;
        cursor: pointer;
    }
}

.modal-title {
    font-weight: bold;
    color: $vooozer-primary;
}

.podcast-image {
    opacity: 1;
    width: 200px;
    height: 200px;
}

.rotated {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.underline {
    text-decoration: underline !important;
}

.podcast-logo {
    &.spotify {
        width: 107px;
    }

    &.google {
        width: 203px;
    }
}

pre {
    background-color: white;
    background-image: -webkit-linear-gradient(top, #f5f5f5 50%, white 50%);
    background-image: -moz-linear-gradient(top, #f5f5f5 50%, white 50%);
    background-image: -ms-linear-gradient(top, #f5f5f5 50%, white 50%);
    background-image: -o-linear-gradient(top, #f5f5f5 50%, white 50%);
    background-image: linear-gradient(top, #f5f5f5 50%, white 50%);
    -webkit-background-size: 30px 30px;
    -moz-background-size: 30px 30px;
    -ms-background-size: 30px 30px;
    -o-background-size: 30px 30px;
    background-size: 30px 30px;
    background-repeat: repeat;
    font: bold 12px/15px "Inconsolata", "Monaco", "Consolas", "Andale Mono", "Bitstream Vera Sans Mono", "Courier New", Courier, monospace;
    color: #333;
    border: 2px solid #666;
    border-radius: 0.25rem;
    position: relative;
    padding: 0 7px;
    margin: 10px 0;
    overflow: auto;
    word-wrap: normal;
    white-space: pre;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    position: relative;

    &[data-codetype] {
        padding: 29px 1em 7px 1em;

        &:before {
            content: attr(data-codetype);
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-color: #666;
            padding: 0 7px;
            font: bold 11px/20px Arial, Sans-Serif;
            color: white;
        }

        &[data-codetype="HTML"] {
            border-color: $vooozer-primary;
            color: #000;

            &[data-codetype="HTML"]:before {
                background-color: $vooozer-primary;
            }
        }
    }
}

.section-title {
    font-size: 19px;
}

tr.valign-text {
    td {
        vertical-align: middle !important;
    }
}

.divisor-top {
    border-top: 1px solid #e6e6e6;
    padding-top: 22px !important;
    margin-right: 15px;
    margin-left: 15px;
}

.user-panel {
    .info {
        a {
            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.hover-white-text {
    color: $white;
    opacity: 0.8;

    &:hover {
        opacity: 1;
        cursor: pointer;
    }
}