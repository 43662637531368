
/***************************
player vooozer
*****************************/
.player-vooozer {
    position: relative;
    background-color: #662D91;
    width: 100%;
    height: 83px;
    display: flex;
    overflow: hidden;
    color: white !important;
    border-radius: 8px;
}

/***************************
buttons
*****************************/

.btn-control-player {
    background-color: transparent !important;
}

.btn-control-player i {
    color: #ffffff !important;
}

.btn-replay i{
    transform: rotate(130deg);
}

.btn-control-player:focus {
    outline: none !important;
    box-shadow: none !important;
}



.disabled,
disabled {
    opacity: .3 !important;
}


/***************************
Estrutura
*****************************/


/*** player left ***/

.player-left {
    display: table-cell;
    position: relative;
    margin-right: 30px;
}


/* controles */

.controles {
    display: flex;
}

.btn-replay{
    font-size: 20px;
    z-index: 5;
    left: 30px;
    position: absolute;
    height: 40%;
}

.barra-centro{
    width: 100%;
    left: 20%;
    top: 40px;
    position: absolute;
    /* height: 20%; */
}

.barra-texto {
    width: 60%;
    text-transform: uppercase;
    position: absolute;
    text-align: center;
    margin-top: -20px;
    font-family: 'Amiko', sans-serif;
    font-weight: bold;
    font-size: 14px;
}

.barra-texto a {
    color: #F26522;
}

.white-label {
    color: #FFF !important;
}

.logo-vooozer-responsive-posplay{
    margin-top: 13px;
    width: 79%;
    text-align: right;
}

.barra-texto p{
    margin:0 !important;
}

/* btn */

.btn-rotate-left,
.btn-play {
    float: left;
}


/* btn left */

.btn-rotate-left {
    width: 55px;
    height: 80px;
}

.btn-rotate-left:active, .btn-rotate-right:active {
    background-color: #FFF;
}

.btn-rotate-left p {
    font-size: 1rem;
    font-weight: 300;
}

/* btn play & btn pause */
/* btn rotate right */

.btn-rotate-right {
    width: 55px;
    height: 80px;
}


.btn-rotate-left,
.btn-rotate-right {
    font-size: 0.8rem;
    width: inherit;
    margin-right: -18px;
}

.btn-play {
    font-size: 3.5rem;
    width: inherit;
    margin-right: -18px;
    margin-left: 0px;
}

.btn-rotate-right p {
    font-size: 1rem;
    font-weight: 300;
}


/*** player center ****/

.player-center {
    width: 100%;
    display: table-cell;
    position: relative;
}

.player-center h1 {
    font-weight: 300;
}

.tempo-player,
.tempo-player-responsive {
    position: relative;
}


/* controles */

.center {
    display: flex;
}


/* tempo player */

.tempo-player h2 {
    font-size: 4rem;
    font-weight: 700;
}

.tempo-player-responsive {
    margin-top: -10px;
}

.tempo-player-responsive-ab-test {
    margin-top: -15px;
    margin-left: -17px;
    padding-right: 25px;
}

.tempo-player-responsive-ab-test h2 {
    font-size: 2rem;
    font-weight: 700;
}

.tempo-player-responsive h2 {
    font-size: 2rem;
    font-weight: 900;
}


.tempo-player-responsive.noaudio h2 {
    font-size: 1.4rem;
    font-weight: 700;
    padding-top: 5px;
}

.barra-download {
    width: 99%;
    margin-top: 40px;
    position: absolute;
}




/* barra de progresso */

.range {
    -webkit-appearance: none;
    background: linear-gradient(to right, #F26522 0%, #F26522 50%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0.5) 62.5%, rgba(255, 255, 255, 0.15) 62.5%, rgba(255, 255, 255, 0.15) 100%);
    cursor: pointer;
    width: 100%;
    height: 5px;
    margin: 0;
    transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -ms-transition: 0.1s ease-in;
}

.barra-progresso:hover .range,
.barra-progresso.hover .range {
    height: 10px;
}

.range::-webkit-slider-thumb {
    width: 0;
    height: 0;
    background-color: #F26522;
    -webkit-appearance: none;
    box-shadow: inset 0 0 0 10px #F26522;
    transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -ms-transition: 0.1s ease-in;
}

.barra-progresso:hover .range::-webkit-slider-thumb,
.barra-progresso.hover .range::-webkit-slider-thumb {
    width: 4px;
    height: 14px;
    margin-bottom: 4px;
    background-color: #F26522;
}

:focus {
    outline: none;
}

.loaded .range,
.loaded .range::-webkit-slider-thumb {
    transition: 0.1s ease-in;
    -webkit-transition: 0.1s ease-in;
    -moz-transition: 0.1s ease-in;
    -ms-transition: 0.1s ease-in;
}


/* download-narrativa */


.download-narrativa {
    /* margin-top: 5px; */
    float: left;
    padding-left: 16px;
    display: flex;
    /*width: 31px;*/
}

.btn-download {
    font-size: 20px;
    padding: 0;
    margin-left: -17px;
    max-height: 37px;
}


/* musica e gostou */

.musica-gostou {
    width: 28em;
    display: inline;
    margin-top: 20px;
}

.musica-gostou .btn-musica {
    font-size: 24px;
    color: #F26522;
}

.musica-gostou a {
    color: #F26522;
}

.musica-gostou a .btn-gostou {
    font-size: 18px;
    vertical-align: inherit;
}

.musica-gostou a span {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}


/* responsive btn-musica */

.btn-musica-responsive {
    font-size: 24px;
    color: #F26522;
    position: relative;
    left: -13px;
    bottom: 7px;
    padding-bottom: 0;
    padding-top: 5px;
    max-height: 37px;
}


/* responsive btn-gostou */

.btn-gostou-responsive {
    color: #F26522;
    font-size: 18px;
    vertical-align: inherit;
}

.btn-gostou-responsive span {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}


.btn-gostou-responsive :hover {
    color: #F26522 !important;
    font-size: 18px;
    vertical-align: inherit;
}

.noHover {
    padding: 6px 12px;
    position: absolute;
    right: 30px;
}

.logo-vooozer-responsive {
    margin-top: 8px;
    width: 100%;
    text-align: right;
}


/*** player right ***/

.player-right {
    width: 210px;
    display: table-cell;
    border-left: 1px #522276 solid;
    padding-left: 15px;
}


/* institucional */

.institucional {
    padding-top: 5px 0;
}

.institucional .plays {
    float: left;
    width: 80px;
    margin-top: 8px;
}


/* play responsive */

.plays-responsive {
    display: inline-block;
}


/* Logo Vooozer */

.logo-vooozer {
    display: block;
}

.logo-vooozer img {
    width: 90px;
    height: 2.5em;
}


/* compartilhar na rede social */

.btn-compartilhar {
    font-size: 28px;
    padding: 0 10px;
    height: 1.3em;
    display: table-header-group;
    z-index: 3;
}

.btn-compartilhar:hover .mostra-icons,
.btn-compartilhar:active .mostra-icons {
    display: block;
}

.btn-compartilhar::before {
    content: '\f1e0';
    font-family: "FontAwesome";
    display: inline-block;
    cursor: pointer;
}

.mostra-icons {
    display: none;
    background-color: white;
    position: absolute;
    top: 7px;
    right: 48px;
    padding: 3px 5px 0 10px;
    border-radius: 4px;
    width: 187px;
    height: 50px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.30);
}

.mostra-icons::before {
    content: "";
    float: right;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid white;
    position: absolute;
    right: -7px;
    top: 7px;
}

.mostra-icons a :hover,
.mostra-icons a:hover {
    text-transform: none;
}

.mostra-icons a .fa-twitter-square {
    font-size: 1.5em;
    color: #55ACEE;
}

.mostra-icons a:hover .fa-linkedin,
.mostra-icons a:hover .fa-whatsapp,
.mostra-icons a:hover .fa-twitter-square,
.mostra-icons a:hover .fa-facebook-square {
    opacity: 0.5;
}

.mostra-icons a .fa-facebook-square {
    font-size: 1.5em;
    color: #3B5998;
}

.mostra-icons a .fa-linkedin {
    font-size: 1.5em;
    color: #0077B5;
}

.mostra-icons a .fa-whatsapp {
    font-size: 1.5em;
    color: #189D0E;
}




/* compartilhar na rede social - responsive */

.btn-compartilhar-responsive {
    font-size: 25px;
    padding: 0 10px;
    height: 1.3em;
    display: table-header-group;
    z-index: 3;
    position: absolute;
    right: 0px;
    top: 2px;
}

.btn-compartilhar-responsive h2 {
    font-size: 2rem;
    margin-top: 7px;
}


#fa-foward30 {
    font-size: 2rem;
    transform: scaleX(-1);
}


#fa-back30 {
    font-size: 2rem;
}


.btn-compartilhar-responsive:hover .mostra-icons-responsive,
.btn-compartilhar-responsive:active .mostra-icons-responsive {
    display: block;
}


.mostra-icons-responsive {
    display: none;
    background-color: white;
    position: absolute;
    top: 3px;
    right: 40px;
    padding: 3px 5px 0 10px;
    border-radius: 4px;
    width: 187px;
    height: 50px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.30);
}

.mostra-icons-responsive::before {
    content: "";
    float: right;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid white;
    position: absolute;
    right: -7px;
    top: 7px;
}

.mostra-icons-responsive a :hover,
.mostra-icons-responsive a:hover {
    text-transform: none;
}

.mostra-icons-responsive a .fa-twitter-square {
    font-size: 1.7em;
    color: #55ACEE;
}

.mostra-icons-responsive a:hover .fa-linkedin,
.mostra-icons-responsive a:hover .fa-whatsapp,
.mostra-icons-responsive a:hover .fa-twitter-square,
.mostra-icons-responsive a:hover .fa-facebook-square {
    opacity: 0.5;
}

.mostra-icons-responsive a .fa-facebook-square {
    font-size: 1.7em;
    color: #3B5998;
}
.mostra-icons-responsive a .fa-linkedin {
    font-size: 1.7em;
    color: #0077B5;
}

.mostra-icons-responsive a .fa-whatsapp {
    font-size: 1.7em;
    color: #189D0E;
}


/******************************************************
responsive
********************************************************/


/* 866px */

@media (max-width: 866px) {}


/* 756px */

@media (max-width: 756px) {}


/* 690px */

@media (max-width: 690px) {
    .barra-texto{
        margin-top: -20px;
    }
}


/* 598px */

@media (max-width: 598px) {
    .barra-texto{
        margin-top: -20px;
    }
}


/* 480px */

@media (max-width: 480px) {

    .barra-texto{
        margin-top: -20px;
    }

}


/* 320px */

@media (max-width: 320px) {


    .barra-texto{
        margin-top: -20px;
    }

}


/*==================================================
responsive
==================================================*/


/*==========  Mobile First Method  ==========*/


/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) {}


/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) {}


/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {}


/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {}


/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {}


/*==========  Non-Mobile First Method  ==========*/


/* Large Devices, Wide Screens */

@media only screen and (max-width: 1200px) {}


/* Medium Devices, Desktops */

@media only screen and (max-width: 992px) {

    .barra-download {
        margin-top: 40px;
    }

    .musica-gostou {
        width: 13em;
    }
    .btn-gostou span {
        display: none;
    }
    .logo-vooozer-responsive {
        margin-top: 8px;
        width: 100%;
        text-align: right;
    }
    #numPlays2 {
        margin-right: 10px;
        margin-top: 3px;
        float: left;
    }
    #linkVooozer {
        float: left;
        width: 15%;
    }
    .btn-compartilhar-responsive {
        position: absolute;
        right: 0px;
        top: 2px;
    }
    #heart {
        position: absolute;
        right: 30px;
    }
    .center {
        width: 105%;
    }
}


/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {
    /*.btn-play {
        width: 50px;
        font-size: 5rem;
        margin-left: -5px;
    }*/
    .btn-rotate-left,
    .btn-rotate-right {
        /*margin-top: 0.5em;*/
    }
    .btn-rotate-left .fa-stack-2x,
    .btn-rotate-right .fa-stack-2x {
        /*font-size: 1.6em;*/
    }
    .btn-rotate-left p,
    .btn-rotate-right p {
        display: none;
    }
    .barra-download {
        margin-top: 40px;
        position: absolute;
        margin-left: 0;
    }
    .center {
        width: 70%;
    }
    .tempo-player {
        display: none;
    }
    .tempo-player-responsive {
        display: block;
    }
    .musica-gostou {
        width: 20em;
        display: table;
    }
    .player-right {
        width: 250px;
    }
    /*.player-center {
        margin-left: -15px;
    }*/
}


/* @media only screen and (max-width : 678px) {

	} */

@media (max-width: 701px) and (min-width: 650px) {
    .player-center {
        width: 90%;
    }
    .barra-download {
        width: 98%;
    }
    .musica-gostou {
        width: 18em;
        display: table;
    }
}

@media (max-width: 650px) and (min-width: 481px) {
    .player-center {
        width: 90%;
    }
    .musica-gostou {
        width: 12em;
        display: inline-table;
    }
    .barra-download {
        width: 98%;
    }
}

@media (max-width: 550px) and (min-width: 481px) {
    .barra-download {
        width: 98%;
    }
    .player-center {
        width: 90%;
    }
    .musica-gostou {
        width: 10em;
        display: inline-table;
    }
}

@media (max-width: 530px) and (min-width: 481px) {
    .barra-download {
        width: 98%;
    }
    .player-center {
        width: 90%;
    }
    .musica-gostou {
        width: 6.5em;
        display: inline-table;
    }
}


/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
    .barra-download {
        width: 96%;
    }
    .center {
        float: left;
        width: 120px;
    }
    .btn-compartilhar-responsive {
        position: absolute;
        right: 0px;
        top: 2px;
    }
    .btn-gostou-responsive {
        right: 30px;
        position: absolute;
    }
    .btn-gostou-responsive span {
        display: none;
    }
    /*.logo-vooozer-responsive img {
			width: 60px;
			height: 1em;
		}*/
}


/* Custom, iPhone Retina */

@media only screen and (max-width: 320px) {
    .player-center {
        margin-left: 5px;
    }
    .controles {
        margin-left: -6px;
    }
    .btn-rotate-left,
    .btn-rotate-right {
        font-size: 0.8rem;
        width: inherit;
        margin-right: -18px;
    }
    .btn-play {
        font-size: 4rem;
        width: inherit;
        margin-right: -18px;
        margin-left: 0px;
    }
    .player-left {
        margin-right: 7px;
    }
    .btn-gostou-responsive span {
        display: none;
    }
    .btn-download {
        font-size: 20px;
    }
    .btn-musica-responsive {
        font-size: 16px;
        bottom: 5px;
    }
    .plays-responsive {
        position: relative;
        right: 9px;
    }
    /*.logo-vooozer-responsive {
			position: absolute;
			right: -10px;
			margin-top: 3px;
		}
		.logo-vooozer-responsive img {
			width: 60px;
			height: 1em;
		}*/
}

.fixedBar {
    width: 100%;
    height: 5px;
    background-color: #FFF;
    opacity: 0.5;
    z-index: 1;
    border-radius: 10px;
}

.bufferBar {
    margin-top: -5px;
    height: 5px;
    background-color: #FFF;
    opacity: 0.75;
    z-index: 2;
    width: 100%;
    border-radius: 10px;
}

.progressBar {
    height: 5px;
    margin-top: -5px;
    background-color: #F26522;
    z-index: 3;
    position: absolute;
    width: 25%;
    border-radius: 10px;
}
.leadCaptureWindow {
    width: 100%;
    height: 83px;
    position: absolute;
    z-index: 5;
}
.leadCaptureOpaque {
    width: 100%;
    height: 83px;
    position: absolute;
    opacity: 0.9;
    background-color: #662D91;
    border-radius: 8px;
}
.leadCaptureSolid {
    width: 100%;
    height: 83px;
    position: absolute;
    opacity: 1;
}

 .leadCaptureMessage {
    font-size: 1em;
    white-space: nowrap;
}
.leadCaptureEmailboxFinetune {
    padding-right: 2%; height: 32px
}
.leadCaptureEmailbox {
    height: 32px !important;
    outline: none !important;
    -webkit-border-bottom-left-radius: 3px !important;
    -webkit-border-top-left-radius: 3px !important;
    -moz-border-bottom-left-radius: 3px !important;
    -moz-border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-top-left-radius: 3px !important;
    border-color: #662D91 !important;
    border-right-color: #F26522 !important;
    box-shadow: none !important;
    border-color: transparent !important;
}
.leadCaptureBtn {
    border-color: #f26522;
    background-color: #f26522;
    height: 32px !important;
    outline: none !important;
}
.leadCaptureBtn:hover {
    opacity: 1;
}

/* STYLE INDEX EMBED */
.leadCaptureFinetune {
    padding-left: 2%;
    padding-top: 2px;
}
.leadCaptureFinetune > p {
    margin-bottom: 2px;
}
.leadCaptureClose {
    color: #fff;
    right: 10px;
    position: absolute;
    padding-top: 4px;
}
/* END: STYLE INDEX EMBED */

.leadCaptureFinetuneName{
    padding-left: 2%;
    padding-top: 2px;
}
.leadCaptureFinetuneName > p {
    margin-bottom: 2px;
}
.leadCaptureCloseName {
    color: #fff;
    right: 10px;
    position: fixed;
    padding-top: 4px;
}
.leadEmailHighlighted {
    font-style: italic;
}
#leadResetEmail {
    color: #DB5A0E;
}

#leadResetEmail:hover {
    color: #DB5A0E;
}

.text-options, .text-options a {
    text-transform: uppercase;
    font-size: 17px;
    color: white;
    text-align: center;
    padding-top: 21px;
    margin: auto;
    width: 100%;
    line-height: 0;
    font-family: 'Amiko', sans-serif;
    font-weight: bold;
}

.text-highlight {
    color: #DB5A0E;
}

#logo-screen {
    background-color: #662D91;
}

.cta-abtest {
    margin-bottom: -30px;
    margin-top: 9px;
    font-weight: 700;
    font-size: 16px;
}
/*# sourceMappingURL=player.css.map */