//
// Custom: Cards
//

.card-section {
    margin-right: 10px;
    margin-left: 10px;

    >.card-header {
        background-color: $vooozer-primary !important;
    }
}

.card-primary {
    &.card-outline {
        border-top: 3px solid;
        border-color: $vooozer-primary;
    }

    &.card-outline-tabs >.card-header {
        a.active {
            border-top: 3px solid $vooozer-primary;
        }
    }
}

.card-secondary {
    &.card-outline {
        border-top: 3px solid;
        border-color: $vooozer-secondary;
    }

    &.card-outline-tabs >.card-header {
        a.active {
            border-top: 3px solid $vooozer-secondary;
        }
    }
}

.nav-link {
    color: $vooozer-primary;
}

.info-box-text {
    font-size: 16px !important;
}

.info-box-number {
    font-size: 16px !important;
}

.tab-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 0 solid #f4f4f4;
}