//
// Custom: Forms
//

.form-control {
    &:focus {
        border-color: $vooozer-primary;
    }
}

.switch-toggle {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;

    &:checked {
        +label {
            background: $vooozer-secondary;

            &::after {
                left: calc(100% - 5px);
                transform: translateX(-100%);
            }
        }
    }
}

.switch-toggle-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 46px;
    height: 25px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 15px;
        height: 15px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }

    &:active {
        &::after {
            width: 35px;
        }
    }
}

.tags-datatable {
    display: none;
    // width: 425px;
}

.tags-and-or {}

.select2-datatable-tags {
    +.select2-container {
        width: 100% !important;

        .select2-selection__rendered {
            line-height: 26.4px !important
        }
    }

    .select2-selection__arrow {
        height: 34px !important;
    }
}

.select2-selection--multiple {
    border-color: rgba(34, 36, 38, .15) !important;
}

// .tags-datatable .and-or-select > label {
//     width: 115px;
//     margin-top: 6px;
//     margin-right: 6px;
// }

// .tags-datatable .and-or-select > label > select.tags-and-or {
//     margin-left: 0.2em;
// }

// .tags-datatable .tags-list {
//     width: 100%;
// }

/* From uiverse.io by @mrhyddenn */
/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 55px;
    height: 30px;
    margin-bottom: 0 !important;

    input {
        opacity: 1;
        width: 0;
        height: 0;
    }
}

span.switch-text {
    margin-left: 0.5rem !important;
    font-weight: bolder;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0px;
    background: #fff;
    transition: .4s;
    border-radius: 30px;
    border: 1px solid #ccc;

    &:before {
        position: absolute;
        content: "";
        height: 1.6em;
        width: 1.6em;
        border-radius: 16px;
        left: 1.2px;
        top: 0;
        bottom: 0;
        background-color: white;
        box-shadow: 0 2px 5px #999999;
        transition: .4s;
    }
}

input:checked+.slider {
    background-color: $vooozer-secondary;
    border: 1px solid transparent;

    &:before {
        transform: translateX(1.5em);
    }
}

.input-group {
    &.sm {
        width: 120px !important;
    }
}

/* INPUT COLOR PICKER */
input[type="color"] {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	background: none;
	border: 0;
	cursor: pointer;
	height: 40px;
	border-radius: 20%;
	padding: 0;
	width: 40px;
}

::-webkit-color-swatch-wrapper {
	padding: 0;
}

::-webkit-color-swatch {
	border: 1px solid #e6e6e6;
	border-radius: 50%;
}

::-moz-color-swatch,
::-moz-focus-inner {
	border: 0;
}

::-moz-focus-inner {
	padding: 0;
}
/* INPUT COLOR PICKER - END */