//
// Custom: charts
//

.chart-info-section {
    .stream-info {
        color: $vooozer-primary;
    }

    .complete-stream-info {
        color: $vooozer-secondary;
    }

    .downloads-info {
        color: $gray-800;
    }

    .leads-generated-info {
        color: $green-alt;
    }
}