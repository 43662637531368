.player-container {

  $orange: #f26522;
  $purple: #662d91;

/*  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }*/

  button,
  input,
  textarea,
  select {
    border: none;
    outline: 0;
  }

  label{
    margin-bottom: 0px !important;
  }

  width: 100%;
  background: $purple;
  height: 83px;
  display: flex;
  align-items: center;
  padding: 0 22px;
  padding-right: 15px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  margin-bottom: 2%;

  .email-form,
  .name-form {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 0 26px 0 12px;
    z-index: 1;

    transition: all 0.5s ease;

    &.hidden {
      opacity: 0;
      z-index: -1;
    }

    .close-form {
      position: absolute;
      top: 8px;
      right: 8px;
      cursor: pointer;
      font-size: 1rem;
      &:hover {
        color: $orange;
      }
    }

    .form-group {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    label {
      display: block;
      color: #fff;
      font-size: 0.875rem;
      font-weight: 300;
      &.hidden {
        display: none;
      }
    }
    .form-control {
      border: none;
      background-color: #fff;
      height: 32px;
      padding: 6px 12px;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      display: block;
      margin: 2px 0;
      font-size: 0.875rem;
      line-height: 1.5;
      color: #555;
      font-family: 'Source Sans Pro2', sans-serif !important;
      &::placeholder {
        color: #aaa;
      }
    }

    .error-msg {
      font-weight: 600;
      &.hidden {
        display: none;
      }
    }

    .field-container {
      display: flex;
      align-items: center;
      .form-control {
        flex: 1;

        &.with-error {
          border: solid 1px red;
        }
      }
      button {
        cursor: pointer;
        height: 32px;
        background-color: $orange;
        color: #fff;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: 500;
        font-size: 0.875rem;
        padding: 0 12px;
        font-family: 'Source Sans Pro2' !important;
      }
    }
  }

  .player-controls {
    flex: 0 0 auto;
    gap: 10px;
    margin-right: 22px;
    align-items: center;
    display: flex;
    height: 100%;

    button {
      background: none;
      transition: all 0.2s ease;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }
  .player-progress-container {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .player-texts {
      justify-content: space-between;
      display: flex;
      align-items: center;
      flex: 1;

      span {
        color: #fff;
        font-size: 1rem;
      }
      .play-time {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.375rem;
        font-family: 'Source Sans Pro2', sans-serif !important;
        letter-spacing: 1px;
      }
      .play-speed {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.375rem;
        font-family: 'Source Sans Pro2', sans-serif !important;
      }
    }
    .bar-overlay {
      height: 5px;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.75);
      width: 100%;
      align-self: center;
      .player-progress-bar {
        height: 5px;
        border-radius: 10px;
        background-color: $orange;
        width: 20%;
      }
    }
    .logo-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      .download-ico {
        font-weight: 900;
        font-size: 1.25rem;
        margin-bottom: 8px;
      }
      img {
        width: 52px;
      }
    }
  }
}

.text-red {
  color: red;
}

a {
  color: $orange;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

i.fa {
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
  &.fa-play,
  &.fa-pause {
    font-size: 2.1875rem;
  }
}
